$(document).ready(function () {
  $('.owl-carousel.carousel-owlc__wrapper.review').owlCarousel({
    loop: true,
    margin: 20,
    nav: true,
    navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
    responsiveClass: true,
    autoplayTimeout:50000,
    responsive: {
      0: {
        items: 1,
        nav: true,
        center:true
      },
      320: {
        items: 1,
        nav: true,
        center:true
      },
      414: {
        items: 1,
        nav: true,
        margin: 10,
        center:true
      },
      600: {
        items: 1,
        nav: true,
        margin: 10,
        center:true
      },
      678: {
        items: 1,
        nav: true,
        margin: 10,
        center:true
      },
      1000: {
        items: 3,
        nav: true,
        loop: true
      }
    }
  });
});