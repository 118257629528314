//Review Modal Записаться к врачу
$(document).ready(function($){

  //openReview Modal
  $('.callback-btn_doctor').on('click', function(event){
    event.preventDefault();
    $('.cd-popup.cd-popup_doctor').addClass('is-visible');
  });
  $("input.disable").attr("readonly", true);

});