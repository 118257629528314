$(document).ready(function () {
  $('.owl-carousel.banner-slider').owlCarousel({
    center: true,
    items: 1,
    loop: true,
    dots: true,
    dotsEach: false,
    dotsData: false,
    dotsSpeed: false,
    dotsContainer: false,
    nav: true,
    margin: 70,
    autoplay: true,
    responsive: {
      300: {
        items: 1,
        margin: 0
      },
      480: {
        items: 1,
        margin: 0
      },
      760: {
        items: 1,
        margin: 0,
        nav: false,
      },
      800: {
        items: 1
      },
      1024: {
        items: 1
      },
      1280: {
        items: 1
      }
    }
  });
});
